import React from 'react'
import Chart from 'react-apexcharts'
import './capability-levels-chart.scss'

export function CapabilityLevelsChart(props) {
  const data = {
    options: {
      chart: {
        width: '100%',
        height: 380,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          'CAP-3',
          'CAP-6',
          'CAP-17',
          'CAP-23',
          'CAP-25',
          'CAP-33',
          'CAP-51',
          'CAP-75',
          'CAP-85'
        ]
      }
    },
    series: [
      {
        name: 'CRL',
        data: [3, 2, 8, 1, 1, 2, 4, 5, 7]
      }
    ]
  }

  return (
    <Chart
      className="capability-levels-chart"
      options={data.options}
      series={data.series}
    />
  )
}
