import React from 'react'
import Chart from 'react-apexcharts'

export function RadarMap() {
  const data = {
    options: {
      // labels: ['Company years', 'Last year income', 'Employees', 'Suppler years', 'Capabilites completed', 'Capabilitites open'],
      labels: ['', '', '', '', '', ''],
      chart: {
        width: '100%',
        height: '500px',
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      }
    },
    series: [
      {
        name: 'Series 1',
        data: [80, 50, 30, 40, 100, 20]
      }
    ]
  }

  return (
    <div id="chart">
      <Chart
        className="chart"
        options={data.options}
        series={data.series}
        type="radar"
      />
    </div>
  )
}
