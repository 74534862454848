import { put, delay, takeEvery, call } from 'redux-saga/effects'

import {
  buyerActionTypes,
  loadBuyersActionSuccess,
  loadBuyersActionError
} from '../actions'
import { getBuyers, getBuyer } from '../../services'
import { loadBuyerActionSuccess, loadBuyerActionError } from '../actions'

export function* loadBuyersSaga() {
  //yield delay(100)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const capabilities = yield call(getBuyers)
    yield put(loadBuyersActionSuccess(capabilities))
  } catch (e) {
    yield put(loadBuyersActionError(e))
  }
}

export function* watchLoadBuyersSaga() {
  yield takeEvery(buyerActionTypes.LOAD_BUYERS, loadBuyersSaga)
}

export function* loadBuyerSaga({ payload }) {
  //yield delay(100)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const buyer = yield call(getBuyer, payload)
    yield put(loadBuyerActionSuccess(buyer))
  } catch (e) {
    yield put(loadBuyerActionError(e))
  }
}

export function* watchLoadBuyerSaga() {
  yield takeEvery(buyerActionTypes.LOAD_BUYER, loadBuyerSaga)
}
