export const CAPABILITY_STATUS = {
  DISABLED: 'disabled',
  OPEN: 'open',
  PENDING_FEEDBACK: 'feedback',
  REJECTED: 'rejected',
  VALIDATED: 'validated',
  CLOSED: 'validated',
}

export const CAPABILITY_SORT = {
  STATUS: 'status',
  DEADLINE: 'deadline',
  CRL: 'crl',
  UPDATED: 'updated',
  CREATED: 'created',
}