import React from 'react'
// Styles
import './ui.scss'

import { CapabilityHeatMap } from '../../components/charts/capability-heat-map'
import { CapabilitySupplierMap } from '../../components/charts/capability-supplier-map'
import { CapabilityLevelsChart } from '../../components/charts/capability-levels-chart'
import { ButtonGroup } from '../../components/button-group/button-group'
import { RadarMap } from '../../components/charts/radar-chart'

export function Ui(props) {
  const actions = [
    {
      children: <span className="material-icons">business</span>,
      action: () => console.log('action')
    },
    {
      children: 'Button',
      action: () => console.log('action')
    }
  ]
  return (
    <div className="ui">
      <CapabilityHeatMap></CapabilityHeatMap>
      <CapabilitySupplierMap></CapabilitySupplierMap>
      <CapabilityLevelsChart></CapabilityLevelsChart>
      <RadarMap></RadarMap>
      <ButtonGroup actions={actions}></ButtonGroup>
    </div>
  )
}
