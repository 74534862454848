const buyers = [
  {
    id: '1',
    name: 'Jessica Paetz',
    country: 'France',
    city: 'Paris',
    suppliers: [{
      name: '2MATECH',
      id: '1'
    }, {
      name: '3A COMPOSITES',
      id: '2'
    }],
    created: new Date()
  },
  {
    id: '2',
    name: 'Moritz Sandel',
    country: 'France',
    city: 'Paris',
    suppliers: [{
      name: '3D ICOM GMBH & CO KG',
      id: '3'
    }, {
      name: '3M ASD DIVISON PLANT',
      id: '5'
    }, {
      name: '3P PRODUCTOS PLASTICOS PERFORMANTES',
      id: '25'
    }],
    created: new Date()
  },
  {
    id: '3',
    name: 'Jonh Doe',
    country: 'France',
    city: 'Paris',
    suppliers: [{
      name: '3D DEUTSCHLAND',
      id: '8'
    }, {
      name: '3M COMPANY',
      id: '7'
    }],
    created: new Date()
  },
  {
    id: 'buyer4',
    name: 'Sarah Connors',
    country: 'France',
    city: 'Paris',
    suppliers:[],
    created: new Date()
  },
  {
    id: 'buyer5',
    name: 'Laura Sandel',
    country: 'France',
    city: 'Paris',
    suppliers:[],
    created: new Date()
  }
]

export const getBuyers = () => {
  return Promise.resolve(buyers)
}

export const getBuyer = (buyerId) => {
  return Promise.resolve(buyers.find(buyer => buyer.id === buyerId))
}
