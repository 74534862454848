import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import {
  selectCapability,
  selectCapabilitiesIsLoading
} from '../../store/selectors'
import { Link } from 'react-router-dom'

import ActivityStream from '../../components/activity-stream/activity-stream'
// Styles
import './sub-capability-detail.scss'
import { CapabilityHeatMap } from '../../components/charts/capability-heat-map'
import Crl from '../../components/crl/crl'
import { ButtonGroup } from '../../components/button-group/button-group'

export default function SubCapabilityDetail(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCapabilityAction(props.match.params.capabilityId))
  }, [])

  const capability = useSelector(selectCapability)
  const loading = useSelector(selectCapabilitiesIsLoading)

  if (loading) {
    return (
      <div className="page page-capability">
        <div className="content loading">Loading</div>
      </div>
    )
  }

  const subcapabilityId = props.match.params.subcapabilityId

  if (!capability) {
    return (
      <div className="page page-capability">
        <div className="content not-found">Not found</div>
      </div>
    )
  }

  const subcapability = capability.subCapabilities.find(
    i => i.id === subcapabilityId
  )

  if (!subcapability) {
    return (
      <div className="page page-capability">
        <div className="content not-found">Not found sub capability</div>
      </div>
    )
  }

  return (
    <div className="page page-sub-capability">
      <div className="content">
        <div className="bread-crumb">
          <div className="bread">
            <Link to={`/capabilities`}>Capabilities</Link>
          </div>
          <div className="slice">></div>
          <div className="bread">
            <Link to={`/capabilities/${capability.id}`}>
              {capability.title}
            </Link>
          </div>
        </div>

        <div className="element-wr">
          <div className="top-items">
            <div className="element-one">
              <h2>{subcapability.title}</h2>
              <div className="capability-detail">
                <div className="capability-description">
                  {subcapability.description}
                </div>

                <div className="bottom-stuff">
                  <div className="capability-supplier">
                    <h4>Supplier</h4>
                    <div className="capability-supplier-name">
                      <Link to={`/suppliers/${subcapability.supplier.id}`}>
                        {subcapability.supplier.name}
                      </Link>
                    </div>
                  </div>
                  <div className="capability-team-member">
                    <h4>Team Member</h4>
                    <div className="capability-team-member-name">
                      <Link to={`/buyers/${subcapability.team.id}`}>
                        {subcapability.team.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
                <div className="capability-level">
                  <h4>Level</h4>
                  <Crl crl={capability.crl} level={subcapability.level}></Crl>
                </div>

                <div className="actions-general">
                  <h4>Actions</h4>
                  <div className="actions-required">
                    Actions Required: {subcapability.actionsRequired}
                  </div>
                  <div className="action-items">
                    <ButtonGroup
                      actions={[
                        {
                          children: 'Promote level up',
                          action: () => {}
                        },
                        {
                          children: 'Cancel Capability',
                          action: () => {}
                        }
                      ]}
                    ></ButtonGroup>
                  </div>
                </div>
              </div>
          </div>

        <h3>Evidences</h3>
        <div className="evidences">
          {subcapability.evidences.map(e => (
            <div className="evidence">
              <div className="level">Level {e.level}</div>
              <div className="filename">
                <span className="material-icons">attach_file</span>
                <a href={e.path}>{e.filename}</a>
              </div>
            </div>
          ))}
        </div>

        <h3>Activity stream</h3>
        <div className="activity-stream">
          {subcapability.level > 8 && <ActivityStream subcapabilityId={subcapabilityId} level={9} title="Level 9" opened={subcapability.level === 9} activities={subcapability.activity.filter(i =>i.level === 9)} /> }
          {subcapability.level > 7 && <ActivityStream subcapabilityId={subcapabilityId} level={8} title="Level 8" opened={subcapability.level === 8} activities={subcapability.activity.filter(i =>i.level === 8)} /> }
          {subcapability.level > 6 && <ActivityStream subcapabilityId={subcapabilityId} level={7} title="Level 7" opened={subcapability.level === 7} activities={subcapability.activity.filter(i =>i.level === 7)} /> }
          {subcapability.level > 5 && <ActivityStream subcapabilityId={subcapabilityId} level={6} title="Level 6" opened={subcapability.level === 6} activities={subcapability.activity.filter(i =>i.level === 6)} /> }
          {subcapability.level > 4 && <ActivityStream subcapabilityId={subcapabilityId} level={5} title="Level 5" opened={subcapability.level === 5} activities={subcapability.activity.filter(i =>i.level === 5)} /> }
          {subcapability.level > 3 && <ActivityStream subcapabilityId={subcapabilityId} level={4} title="Level 4" opened={subcapability.level === 4} activities={subcapability.activity.filter(i =>i.level === 4)} /> }
          {subcapability.level > 2 && <ActivityStream subcapabilityId={subcapabilityId} level={3} title="Level 3" opened={subcapability.level === 3} activities={subcapability.activity.filter(i =>i.level === 3)} /> }
          {subcapability.level > 1 && <ActivityStream subcapabilityId={subcapabilityId} level={2} title="Level 2" opened={subcapability.level === 2} activities={subcapability.activity.filter(i =>i.level === 2)} /> }
          <ActivityStream subcapabilityId={subcapabilityId} level={1} title="Level 1" opened={subcapability.level === 1} activities={subcapability.activity.filter(i =>i.level === 1)} />
        </div>

        <h3>Activity Heatmap</h3>
        <div className="hmap">
          <CapabilityHeatMap></CapabilityHeatMap>
        </div>
      </div>
    </div>
    </div>
  )
}
