import React from 'react'
// Styles
import './subcapabilityCard.scss'
import Crl from '../crl/crl'
import { Link } from 'react-router-dom'
function formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  var hour = date.getHours()
  var minutes = (date.getMinutes()<10?'0':'') + date.getMinutes()

  return day + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + hour + ':' + minutes
}

export default function SubcapabilityCard({ subcapability, capability }) {
  return (

  <div className="sub-capability-card">
    <div className="top-info">
        
        <div className="sub-capability-supplier"><strong>{subcapability.title}</strong></div>
        <Crl crl={capability.crl} level={subcapability.level}></Crl>
      </div>
    <div className="topper">
      <div className="sub-capability-date">Last activity: {formatDate(subcapability.activity[0].date)}</div>
    </div>
  
    <div className="bottomer">
      <div className="team">
        <div className="team-title">Team</div>
        <div className="team-members">
          <Link to={`/buyers/${subcapability.team.id}`}>{subcapability.team.name}</Link>
        </div>
      </div>
      <div className="supplier">
        <div className="supplier-title">Supplier</div>
        <div className="supplier-members">
          <Link to={`/suppliers/${subcapability.supplier.id}`}>{subcapability.supplier.name}</Link>
        </div>
      </div>
    </div>
  </div>
  )
}
