import { capabilitiesActionTypes } from '../actions'
import { CAPABILITY_SORT } from '../../constants/capabilities'

export const defaultFilters = {
  fromDate: '',
  toDate: '',
  supplier: '',
  program: '',
  status: '',
  sort: CAPABILITY_SORT.DEADLINE
}

export const initialState = {
  capabilities: [],
  capability: null,
  filters: { ...defaultFilters },
  loading: false,
  error: false
}

export default (state = initialState, action) => {
  const payload = action.payload
  switch (action.type) {
    case capabilitiesActionTypes.LOAD_CAPABILITIES:
      return {
        ...state,
        loading: true,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITIES_SUCCESS:
      return {
        ...state,
        capabilities: payload,
        loading: false,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY:
      return {
        ...state,
        capability: null,
        loading: true,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY_SUCCESS:
      return {
        ...state,
        capability: payload,
        loading: false,
        error: false
      }

    case capabilitiesActionTypes.LOAD_CAPABILITY_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      }

    case capabilitiesActionTypes.ADD_ACTIVITY:
      const oldSubCaps = state.capability.subCapabilities.filter(
        i => i.id !== payload.subcapabilityId
      )
      const newSubCap = state.capability.subCapabilities.find(
        i => i.id === payload.subcapabilityId
      )
      return {
        ...state,
        capability: {
          ...state.capability,
          subCapabilities: [
            ...oldSubCaps,
            {
              ...newSubCap,
              activity: [{ ...payload.activity }, ...newSubCap.activity].filter(
                i => !!i
              )
            }
          ]
        }
      }

    case capabilitiesActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...payload }
      }

    default:
      return state
  }
}
