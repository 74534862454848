import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import {
  selectCapability,
  selectCapabilitiesIsLoading
} from '../../store/selectors'
import { Link } from 'react-router-dom'
import Crl from '../../components/crl/crl'
import SubcapabilityCard from '../../components/subcapabilityCard/subcapabilityCard'
// Styles
import './capability-detail.scss'

export default function CapabilityDetail(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCapabilityAction(props.match.params.capabilityId))
  }, [])

  const capability = useSelector(selectCapability)
  const loading = useSelector(selectCapabilitiesIsLoading)

  if (loading) {
    return (
      <div className="page page-capability">
        <div className="content loading">Loading</div>
      </div>
    )
  }

  if (!capability) {
    return (
      <div className="page page-capability">
        <div className="content not-found">Not found</div>
      </div>
    )
  }

  return (
    <div className="page page-capability">
      <div className="content">
        <div className="bread-crumb">
          <div className="bread">
            <Link to={`/capabilities`}>Capabilities</Link>
          </div>
        </div>
        <div className="title-description">
          <div className="title">
            <h2>{capability.title}</h2>
            <div className="capability-detail">
              <div className="capability-description">{capability.description}</div>
            </div>
          </div>
          
          <div className="overall-crl">
            <h4>Overall CRL</h4>
            <Crl crl={capability.crl} level={capability.level}></Crl>
          </div>
        </div>
        <div className="capabilities-list">
          <h3>Capabilities by supplier</h3>

          {capability.subCapabilities && capability.subCapabilities.map(i => {
            return (<Link to={`/capabilities/${capability.id}/${i.id}`}>
              <SubcapabilityCard subcapability={i} capability={capability} />
            </Link>)
          })}

        </div>
      </div>
    </div>
  )
}
