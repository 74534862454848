import React from 'react'

import { oauth } from '../../services/auth.service';

import './oauth.scss'



export default function OauthLogin(props) {
  const loginAttempt = function () {
    oauth()
    .then((oauthUrl) => {
      window.location = oauthUrl
    })
  }

  return (
    <div className="page login-page">
      <div className="oauth">
        <h6>Oauth page</h6>
        <div className="button-container">
          <button
            type="button"
            name="pass"
            id="pass"
            onClick={e => loginAttempt()}
          >
            Oauth login
          </button>
        </div>
      </div>
    </div>
  )
}
