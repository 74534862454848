import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { selectUserRole } from '../../store/selectors'
import roles from '../../constants/roles'

// Styles
import './home.scss'
import { CapabilityLevelsChart } from '../../components/charts/capability-levels-chart'
import { Kpi } from '../../components/kpi/kpi'
import { ButtonGroup } from '../../components/button-group/button-group'

export default function Home(props) {
  const role = useSelector(selectUserRole)
  const [viewType, setViewType] = useState('kpi')

  const actions = [
    {
      children: <span className="material-icons">trending_up</span>,
      action: () => setViewType('kpi')
    },
    {
      children: <span className="material-icons">view_column</span>,
      action: () => setViewType('kanban')
    }
  ]

  return (
    <div className="page page-home">
      <div className="header">
        {/* {role === roles.GUEST && <div className="test">GUEST LOGGED</div>} */}
        {/* <ButtonGroup actions={actions}></ButtonGroup> */}
      </div>

      {viewType === 'kpi' && (
        <>
          <div className="kpi-list">
            <Link to="/capabilities">
              <Kpi value="10" title="CAPABILITIES OPEN"></Kpi>
            </Link>
            <Link to="/capabilities">
              <Kpi value="2" title="PENDING VALIDATION"></Kpi>
            </Link>
            <Link to="/suppliers">
              <Kpi value="25" title="SUPPLIERS"></Kpi>
            </Link>
          </div>

          <h1>Capabilities CRL</h1>
          <CapabilityLevelsChart></CapabilityLevelsChart>
        </>
      )}
    </div>
  )
}
