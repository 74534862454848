import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadCapabilityAction } from '../../store/actions'
import { selectCapability } from '../../store/selectors'
import { Link } from 'react-router-dom'
// Styles
import './capability-card.scss'
import Crl from '../../components/crl/crl'

export default function CapabilityCard({ capability }) {

  const suppliers = capability.subCapabilities.reduce((prev, next) => {
    return [...prev, next.supplier]
  }, [])

  return (
    <div className="capability-card">
      <div className="top-info">
        <div className="sub-count">{capability.subCapabilities.length} Capabilities</div>
        <Crl crl={capability.crl} level={capability.level}></Crl>
      </div>
      <div className="capability-title"><strong>{capability.title}</strong></div>
      <div className="capability-description">{capability.description}</div>
      <div className="capability-suppliers">
        {suppliers.map(supplier => (
          <div className="supplier">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.name}</Link>
          </div>
        ))}
      </div>

      <div className="actions-required">
        Actions Required: {capability.actionsRequired}
      </div>
    </div>
  )
}
