import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Header from './components/header/header'
import Footer from './components/footer/footer'
import Routes from './routes'

import { selectUserIsLoggedIn } from './store/selectors'

function App() {
  const isLoggedIn = useSelector(selectUserIsLoggedIn)

  return (
    <div className="App">
      <div className="app-body">
        <Header />
        <Switch>
          {Routes.map(route => (
            <Route
              path={route.path}
              key={route.path || 'not-found'}
              exact={route.exact}
              render={(routeProps) => {
                if (!isLoggedIn && route.private) {
                  return (
                    <Redirect to="/login" />
                  )
                }

                return (
                  <route.component {...routeProps} metadata={route.metadata} />
                )
              }}
            />
          ))}
        </Switch>
        {/*<Footer />*/}
      </div>
    </div>
  )
}

export default App
