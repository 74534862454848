import React from 'react'
import './kpi.scss'

export function Kpi({ value, title }) {
  return (
    <div className="kpi">
      <div className="object">
        <span className="material-icons">arrow_back</span>
      </div>
      <div className="values">
        <p class="kpi-value">{value}</p>
        <p class="kpi-title">{title}</p>
      </div>
    </div>
  )
}
