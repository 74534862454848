import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// Styles
import './buyer-detail.scss'

import SubcapabilityCard from '../../components/subcapabilityCard/subcapabilityCard'
// Styles

import { CapabilityLevelsChart } from '../../components/charts/capability-levels-chart'

import { selectBuyer , selectCapabilities} from '../../store/selectors'
import { loadBuyerAction, loadCapabilitiesAction } from '../../store/actions'

function BuyerDetail(props) {
  const dispatch = useDispatch()
  const buyer = useSelector(selectBuyer)
  const capabilities = useSelector(selectCapabilities)
  useEffect(() => {
    dispatch(loadBuyerAction(props.match.params.buyerId))
    dispatch(loadCapabilitiesAction())
  }, [])

  if (!buyer || !capabilities || !capabilities.length) {
    return <div className="page page-buyer">
        <div className="content">
          <div className="loading">Loading</div>
        </div>
      </div>
  }
  return (
    <div className="page page-buyer">
      <div className="content">
        <h2>{buyer.name}</h2>
        <div className="buyer-card">
        <div className="buyer-characteristic"></div>
        <div className="buyer-characteristic"><span className="label">Country</span>: {buyer.country}</div>
        <div className="buyer-characteristic"><span className="label">City</span>: {buyer.city}</div>
        <div className="buyer-characteristic"><span className="label">Suppliers</span>: {buyer.suppliers.map(i => (<span className="supplier"><Link to={`/suppliers/${i.id}`}>{i.name}</Link></span>))}</div>
      </div>

      <div className="capabilities">
        <h2>Capabilities List</h2>
       <div className="capabilities-list">
          {capabilities.map(capability => {
            const subcapabilities = capability.subCapabilities.filter(i => i.team.id === props.match.params.buyerId)
            return subcapabilities.map(subcapability => {
              return (
                <Link to={`/capabilities/${capability.id}/${subcapability.id}`}>
                <SubcapabilityCard capability={capability} subcapability={subcapability}></SubcapabilityCard>
              </Link>
              )

            })
            
            
          }
            
          )}
        </div>
      </div>
      <div className="buyer-charts">
        <h2>Buyer Capabilities Chart</h2>
        <CapabilityLevelsChart></CapabilityLevelsChart>
      </div>
      </div>
    </div>
  )
}

export default withRouter(BuyerDetail)

