import { put, delay, takeEvery, call } from 'redux-saga/effects'

import {
  suppliersActionTypes,
  loadSuppliersActionSuccess,
  loadSuppliersActionError,
  loadSupplierActionSuccess,
  loadSupplierActionError
} from '../actions'
import { getSuppliers, getSupplier } from '../../services'

export function* loadSuppliersSaga({ payload }) {
  // yield delay(100)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const suppliers = yield call(getSuppliers)
    yield put(loadSuppliersActionSuccess(suppliers))
  } catch (e) {
    yield put(loadSuppliersActionError(e))
  }
}

export function* watchLoadSuppliersSaga() {
  yield takeEvery(suppliersActionTypes.LOAD_SUPPLIERS, loadSuppliersSaga)
}


export function* loadSupplierSaga({ payload }) {
  // yield delay(100)
  // TODO remove delay  yield put(closeAlertAction())
  try {
    const suplier = yield call(getSupplier, payload)
    yield put(loadSupplierActionSuccess(suplier))
  } catch (e) {
    yield put(loadSupplierActionError(e))
  }
}

export function* watchLoadSupplierSaga() {
  yield takeEvery(suppliersActionTypes.LOAD_SUPPLIER, loadSupplierSaga)
}
