const pack = require('../../package.json')

export const setData = data => {
  try {
    localStorage.setItem('ddms-' + pack.version, JSON.stringify(data))
  } catch (err) {
    console.log(err)
  }
}

export const getData = () => {
  try {
    return JSON.parse(localStorage.getItem('ddms-' + pack.version)) || {}
  } catch (e) {
    console.log(e)
    return {}
  }
}
