import React from 'react'
import './button-group.scss'

export function ButtonGroup({ actions }) {
  return (
    <div className="button-group">
      {actions.map(option => (
        <button onClick={option.action}>{option.children}</button>
      ))}
    </div>
  )
}
