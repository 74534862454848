import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { oauth } from '../../services/auth.service';

import roles from '../../constants/roles'

import { loginAction } from '../../store/actions'

import './login.scss'

function Login(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const getUser = email => {
    switch (email) {
      case 'guest@ddms.com':
        return {
          role: roles.GUEST
        }

      case 'supplier@ddms.com':
        return {
          role: roles.SUPPLIER
        }

      case 'capteam@ddms.com':
        return {
          role: roles.CAPTEAM
        }

      case 'capsteering@ddms.com':
        return {
          role: roles.CAPSTEERING
        }

      case 'ddmsteam@ddms.com':
        return {
          role: roles.DDMSTEAM
        }

      default:
        return {
          role: roles.GUEST
        }
    }
  }

  const loginNormal = e => {
    e.preventDefault()
    e.stopPropagation()

    const user = getUser(email)

    dispatch(loginAction(user))

    setTimeout(() => {
      props.history.push('/')
    })
  }

  const loginOauth = (event) => {
    event.preventDefault();
    event.stopPropagation();

    oauth()
    .then((oauthUrl) => {
      window.location = oauthUrl
    })
  }

  return (
    <div className="page page-login">
      <div className="content">
        <h1>Airbus DDMS</h1>

        <form className="login-form" onSubmit={loginOauth}>

          <button type="submit" className="login-button">
            <img className="login-image-button" src="/assets/btn-login.png" />
          </button>
        </form>

        <h2>or use email</h2>
        <form className="login-form" onSubmit={loginNormal}>
          <div className="input-item">
            <input
              type="text"
              placeholder="Introduce tu email"
              name="user"
              id="user"
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className="input-item">
            <input
              type="password"
              placeholder="Introduce tu contraseña"
              name="pass"
              id="pass"
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button-email">
            Login
          </button>
        </form>

      </div>
    </div>
  )
}

export default withRouter(Login)
