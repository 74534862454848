import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addActivityAction } from '../../store/actions'

import './activity-stream.scss'
import { CAPABILITY_STATUS } from '../../constants/capabilities'
import { ACTIVITY_STREAM_TYPE } from '../../constants/activity-stream'

const activities = [
  {
    type: ACTIVITY_STREAM_TYPE.TEXT,
    data: {
      initialState: CAPABILITY_STATUS.OPEN
    }
  }
]

function formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

function ActivityItem({activity}) {
  if (activity.type === 'comment') {
    return (
      <div className="activity activity-comment">
          
          <div className="image-text">
            <div className="user-image">
              <img src="/assets/user/manager-demo.jpg" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Philip Moritz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div>
          
          {/* <div className="image-text">
            <div className="user-image">
              <img src="/assets/user/manager-demo.jpg" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Philip Moritz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div>
          
          <div className="image-text alt">
            <div className="user-image">
              <img src="/assets/user/manager-demo.jpg" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Philip Moritz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div> */}
      </div>
    )
  }
  if (activity.type === 'comment-alt') {
    return (
      <div className="activity activity-comment">
          
          <div className="image-text alt">
            <div className="user-image">
              <img src="/assets/user/manager-demo-2.png" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Jessica Paetz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div>
          
          {/* <div className="image-text">
            <div className="user-image">
              <img src="/assets/user/manager-demo.jpg" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Philip Moritz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div>
          
          <div className="image-text alt">
            <div className="user-image">
              <img src="/assets/user/manager-demo.jpg" />
            </div>
            <div className="right">
              <div className="date">Published on: {formatDate(activity.date)}</div>
              <div className="text">
                <strong>Philip Moritz</strong>
                <p>{activity.text}</p>
              </div>
            </div>
          </div> */}
      </div>
    )
  }


  if (activity.type === 'file-upload') {
    return (
      <div className="activity activity-file-upload">
         <div className="image-text">
         <div className="user-image">
           <img src="/assets/user/manager-demo.jpg" />
         </div>
         <div className="right">
           <div className="date">Published on: {formatDate(activity.date)}</div>
           <div className="text">
             <strong>Philip Moritz</strong>
             <p>{activity.text}</p>
           </div>
         </div>
         
        </div>
      </div>

     
    )
  }

  if (activity.type === 'level-up-request') {
    return (
      <div className="activity activity-level-up">
         <div className="image-text">
         <div className="user-image">
           <img src="/assets/user/manager-demo.jpg" />
         </div>
         <div className="right">
           <div className="date">Published on: {formatDate(activity.date)}</div>
           <div className="text">
             <strong>Philip Moritz</strong>
             <p>{activity.text}</p>
           </div>
         </div>
         
        </div>

      </div>
    )
  }
}

export default function ActivityStream(props) {
  const [opened, setOpened] = useState(props.opened || false)
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()

  const addComment = () => {
    dispatch(addActivityAction({
      subcapabilityId: props.subcapabilityId,
      activity: {
        type: 'comment',
        date: new Date(),
        text: message,
        userId: '2',
        level: props.level,
      }
    }))

    setMessage('')
  }

  const requestLevelUp = () => {
    dispatch(addActivityAction({
      subcapabilityId: props.subcapabilityId,
      activity: {
        type: 'level-up-request',
        date: new Date(),
        text: 'The user has requested level up to ' + (props.level + 1),
        userId: '2',
        level: props.level,
      }
    }))
  }


  return (

  <div className="activity-stream">
    <div className="title" onClick={() => setOpened(!opened)}>Level {props.level}</div>
    <div className={`content ${opened ? 'open': ''}`}>
      <div className="activities">
      {props.activities.map(activity => {
        return (
          <ActivityItem activity={activity} />
        )
      })}
      </div>
      <div className="input-user">
        <div className="text">
          <textarea placeholder="Your message…" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
          <button onClick={addComment}>Submit</button>
        </div>
        <div className="controls">
          <button onClick={requestLevelUp}>Level up Request</button>
          <button ><span className="material-icons">attach_file</span>Attach evidence</button>
        </div>
      </div>
    </div>
  </div>
  )
}
