import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { selectBuyers, selectBuyersIsLoading , selectCapabilities} from '../../store/selectors'
import { loadBuyersAction, loadCapabilitiesAction } from '../../store/actions'
// Styles
import './buyer-list.scss'

export default function SupplierList(props) {
  const isLoading = useSelector(selectBuyersIsLoading)
  const buyers = useSelector(selectBuyers)
  const capabilities = useSelector(selectCapabilities)
  const [filterText, setFilterText] = useState('')
  
  const dispatch = useDispatch()

  const onKeySearch = e => {
    setFilterText(e.target.value)
  }

  useEffect(() => {
    dispatch(loadBuyersAction())
    dispatch(loadCapabilitiesAction())
  }, [])

  if (!buyers || !capabilities || !capabilities.length) {
    return <div className="page page-buyers">
        <div className="content">
          <div className="loading">Loading</div>
        </div>
      </div>
  }

  return (
    <div className="page page-buyers">
      <div className="content">
        <h2>Buyers</h2>

        <label>
          <strong>Search</strong> <input onKeyUp={onKeySearch} type="text"></input>
        </label>

        
          <div className="buyers-list">
            {buyers.filter(i => i.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).map(i => {
                const buyerCapabilities = capabilities.reduce((prev, next) => {
                  
                  return prev + next.subCapabilities.filter(s => s.team.id === i.id).length
                }, 0)
              return (
                <Link to={`/buyers/${i.id}`}>
                  <div className="buyer-card">
                    <div className="buyer-name">{i.name}</div>
                    <div className="capabilities-count">{buyerCapabilities} Capabilities</div>
                    <div className="score">70%</div>
                  </div>
                </Link>
              )
            })}
          </div>
      </div>
    </div>
  )
}
