import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import SubcapabilityCard from '../../components/subcapabilityCard/subcapabilityCard'
// Styles
import './supplier-detail.scss'

import { selectSupplier, selectCapabilities } from '../../store/selectors'

import { loadSupplierAction, loadCapabilitiesAction } from '../../store/actions'
import { CapabilityLevelsChart } from '../../components/charts/capability-levels-chart'
import { RadarMap } from '../../components/charts/radar-chart'

export default function SupplierDetail(props) {
  const dispatch = useDispatch()
  const supplier = useSelector(selectSupplier)
  const capabilities = useSelector(selectCapabilities)
  useEffect(() => {
    dispatch(loadSupplierAction(props.match.params.supplierId))
    dispatch(loadCapabilitiesAction())
  }, [])

  if (!supplier || !capabilities || !capabilities.length) {
    return <div className="page page-supplier">
        <div className="content">
          <div className="loading">Loading</div>
        </div>
      </div>
  }

  return (<div className="page page-supplier">
    <div className="content">
      <h1>{supplier.companyName}</h1>

      <div className="header">

        <div className="supplier-card">
          <div className="supplier-characteristic"></div>
          <div className="supplier-characteristic"><span className="label">Country</span>: {supplier.country}</div>
          <div className="supplier-characteristic"><span className="label">City</span>: {supplier.city}</div>
          <div className="supplier-characteristic"><span className="label">Street</span>: {supplier.street}</div>
          <div className="supplier-characteristic"><span className="label">Cage Code</span>: {supplier.cageCode}</div>
        </div>

        <RadarMap></RadarMap>
      </div>

      <div className="capabilities">
        <h2>Capabilities List</h2>
       <div className="capabilities-list">
          {capabilities.map(capability => {
            const subcapabilities = capability.subCapabilities.filter(i => i.supplier.id === props.match.params.supplierId)
            return subcapabilities.map(subcapability => {

              return (
                <Link className="subcapability-item" to={`/capabilities/${capability.id}/${subcapability.id}`}>
                <SubcapabilityCard capability={capability} subcapability={subcapability}></SubcapabilityCard>
              </Link>
              )
            })
            
          
          }
            
          )}
        </div>
      </div>
      <div className="supplier-charts">
        <h2>Suppliers Capabilities Chart</h2>
        <CapabilityLevelsChart></CapabilityLevelsChart>
      </div>
    </div>
  </div>)

}

