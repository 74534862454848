import React, { useState, useEffect } from 'react'
import { Link, withRouter, matchPath } from 'react-router-dom'
import './header.scss'
import { useSelector, useDispatch } from 'react-redux'

import { selectUserIsLoggedIn, selectUserRole } from '../../store/selectors'
import { logoutAction } from '../../store/actions'

function Header(props) {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn)
  const userRole = useSelector(selectUserRole)
  const dispatch = useDispatch()
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const [collapsed, setcollapsed] = useState(false)
  const [menuOpen, setmenuOpen] = useState(false)

  const logout = () => {
    dispatch(logoutAction())

    props.history.push('/login')
  }

  useEffect(() => {
    props.history.listen(() => {
      setmenuOpen(false)
    })
  }, [])

  return (
    <header className="app-header">
      <div className={`header ${collapsed ? 'collapsed' : ''}`}>
        <div className="top-items">
          <Link className="logo-arrow" to="/">
            <img className="logo" src="/assets/airbus-logo.svg" />
            <img className="symbol" src="/assets/airbus-symbol.svg" />

            <button className="subnav-closer">
              <span className="material-icons">arrow_back</span>
            </button>
          </Link>
        </div>

        {!userIsLoggedIn && (
          <ul className={`${menuOpen ? 'menuOpen' : ''}`}>
            <li
              className={
                !!matchPath(props.location.pathname, { path: '/', exact: true })
                  ? 'active'
                  : ''
              }
            >
              {/* <Link to={`/login`} title="home">
                <span className="material-icons">dashboard</span>
                <span className="menu-link">Login</span>
              </Link> */}
            </li>
          </ul>
        )}
        {userIsLoggedIn && (
          <ul className={`${menuOpen ? 'menuOpen' : ''}`}>
            <li
              className={
                !!matchPath(props.location.pathname, { path: '/', exact: true })
                  ? 'active'
                  : ''
              }
            >
              <Link to={`/`} title="home">
                <span className="material-icons">dashboard</span>
                <span className="menu-link">Dashboard</span>
              </Link>
            </li>
            <li
              className={
                !!matchPath(props.location.pathname, '/capabilities')
                  ? 'active'
                  : ''
              }
            >
              <Link to={`/capabilities`} title="capabilities">
                <span className="material-icons">settings_applications</span>
                <span className="menu-link">Capabilities</span>
              </Link>
            </li>
            <li
              className={
                !!matchPath(props.location.pathname, '/suppliers')
                  ? 'active'
                  : ''
              }
            >
              <Link to={`/suppliers`} title="suppliers">
                <span className="material-icons">work</span>
                <span className="menu-link">Suppliers</span>
              </Link>
            </li>
            <li
              className={
                !!matchPath(props.location.pathname, '/buyers') ? 'active' : ''
              }
            >
              <Link to={`/buyers`} title="buyers">
                <span className="material-icons">people</span>
                <span className="menu-link">Team</span>
              </Link>
            </li>

            <li>
              <Link to="/" title="reports">
                <span className="material-icons">trending_up</span>
                <span className="menu-link">Reports</span>
              </Link>
            </li>

            <li>
              <Link to="/" title="logout" onClick={logout}>
                <span className="material-icons">power_settings_new</span>
                <span className="menu-link">Logout</span>
              </Link>
            </li>
          </ul>
        )}

        {/*<div className="user-menu">
          {userIsLoggedIn && (
            <div className="user-menu">
              <div className="user">{userRole}</div>
              <div className="logout" onClick={logout}>
                Logout
              </div>
              <UserBox
                userRole={userRole}
                onClick={() => setNotificationsOpen(!notificationsOpen)}
              />
              {notificationsOpen && <Notifications />}
            </div>
          )}
          {!userIsLoggedIn && (
            <div className="anonymous-menu">
              <div className="user-name">
                <img
                  className="user-avatar"
                  src="/assets/user/manager-demo.jpg"
                />
                <Link to="/login">Login</Link>
              </div>

              <div className="user-notifications">3</div>
            </div>
          )}
          </div>*/}

        <button
          className="menu-expander"
          onClick={() => setcollapsed(!collapsed)}
        >
          <span className="material-icons">arrow_forward</span>
        </button>
        <button className="menu-opener" onClick={() => setmenuOpen(!menuOpen)}>
          <span className="material-icons">menu</span>
        </button>
      </div>
      {/*<Subheader/>*/}
    </header>
  )
}

export default withRouter(Header)
