import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// Styles
import './capability-list.scss'
import { loadCapabilitiesAction } from '../../store/actions'
import {
  selectCapabilities,
  selectCapabilitiesIsLoading
} from '../../store/selectors/capabilities.selector'
import CapabilityCard from './capability-card'
import { CapabilityFilter } from './capability-filters'



export default function CapabilityList(props) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(loadCapabilitiesAction())
  }, [])

  const [filterText, setFilterText] = useState('')
  const capabilitites = useSelector(selectCapabilities)
  const loading = useSelector(selectCapabilitiesIsLoading)

  const onKeySearch = e => {
    setFilterText(e.target.value)
  }

  return (
    <div className="page page-capabilities">

      <div className="content">
        <div className="title-filter">
          <h2>Capabilities</h2>
          <span className="material-icons" onClick={() => setOpen(!open)}>filter_list</span>
        </div>
        <div className="content-list">
          <CapabilityFilter open={open} onTextSearch={onKeySearch}></CapabilityFilter>
          {loading && <div className="loading">Loading</div>}
          {!loading && (
            <div className="capabilities-list">
              {capabilitites.filter((c) => c.title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 )
                .map(capability => (
                <Link className="capability-card-unit" to={`${props.location.pathname}/${capability.id}`}>
                  <CapabilityCard capability={capability}></CapabilityCard>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
