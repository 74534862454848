import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  selectSuppliers,
  selectSuppliersIsLoading,
  selectCapabilities
} from '../../store/selectors'
import { loadSuppliersAction, loadCapabilitiesAction } from '../../store/actions'
// Styles
import './supplier-list.scss'

export default function SupplierList(props) {
  const isLoading = useSelector(selectSuppliersIsLoading)
  const suppliers = useSelector(selectSuppliers)
  const capabilities = useSelector(selectCapabilities)

  const [filterText, setFilterText] = useState('')

  const dispatch = useDispatch()

  const onKeySearch = e => {
    setFilterText(e.target.value)
  }

  useEffect(() => {
    dispatch(loadSuppliersAction())
    dispatch(loadCapabilitiesAction())
  }, [])

  if (!suppliers || !capabilities || !capabilities.length) {
    return <div className="page page-supplier">
        <div className="content">
          <div className="loading">Loading</div>
        </div>
      </div>
  }

  return (
    <div className="page page-suppliers">

      <div className="content">
        <h2>Suppliers</h2>
        
        <label>
          <strong>Search</strong> <input onKeyUp={onKeySearch} type="text"></input>
        </label>
        
        <div className="suppliers-list">
          {suppliers.filter(i => i.companyName.toLowerCase().indexOf(filterText.toLowerCase()) !== -1).map(i => {
            const supplierCapabilities = capabilities.reduce((prev, next) => {
              
              return prev + next.subCapabilities.filter(s => s.supplier.id === i.id).length
            }, 0)
            return <Link to={`/suppliers/${i.id}`}>
              <div className="supplier-card">
                <div className="supplier-name">
                  <div className="supplier-logo">
                    <img src={i.logo}></img>
                  </div>
                  <strong>
                    {i.companyName}
                  </strong>
                </div>
                <div className="capabilities-count">{supplierCapabilities} Capabilities</div>
                <div className="score">70% Score</div>
              </div>
            </Link>
          })}
        </div>
      
      </div>
    </div>
  )
}
