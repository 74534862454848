import React, { useState } from 'react'
// Styles
import './crl.scss'
import { CAPABILITY_STATUS } from '../../constants/capabilities'

const CRL_LEVELS = 9

// CRL => Capability Readiness Level
export default function Crl(props) {
  const [selectedLevel, setSelectedLevel] = useState(null)
  const levelArr = Array.apply(null, Array(CRL_LEVELS))

  // Open levels can be open, request info, rejected, ...etc
  function isLevelOpen(crlLevel, level, levelIndex) {
    return level
      ? levelIndex + 1 == level
      : crlLevel.status !== CAPABILITY_STATUS.DISABLED &&
          crlLevel.status !== CAPABILITY_STATUS.CLOSED
  }

  if (!props.crl ) {
    return null
  }

  return (
    <React.Fragment>
      <ul className="crl">
        {levelArr.map((_, i) => {
          return (
            <li
              className={`${
                isLevelOpen(props.crl[i], props.level, i) ? 'active' : ''
              } ${i == selectedLevel ? 'selected' : ''}`}
              onClick={() => setSelectedLevel(selectedLevel === i ? null : i)}
            >
              {i + 1}
            </li>
          )
        })}
      </ul>
      {props.crl[selectedLevel] && (
        <div className="submenu-crl">
          <b>{props.crl[selectedLevel].title}</b>
          <p>{props.crl[selectedLevel].description}</p>

          <ul className="crl-validations">
            {props.crl[selectedLevel].validations &&
              props.crl[selectedLevel].validations.map(validation => {
                return (
                  <li>
                    <label>
                      <input type="checkbox"></input>
                      {validation.title}
                    </label>
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </React.Fragment>
  )
}
