import React from 'react'
import Chart from 'react-apexcharts'

import './capability-heat-map.scss'

function generateData(count, yrange) {
  var i = 0
  var series = []
  while (i < count) {
    var x = 'w' + (i + 1).toString()
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    series.push({
      x: x,
      y: y
    })
    i++
  }
  return series
}

export function CapabilityHeatMap() {
  const data = {
    options: {
      dataLabels: {
        enabled: false
      },
      colors: ['#008FFB'],
      title: {
        text: 'Activity HeatMap Chart'
      },
      chart: {
        width: '100%',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
    },
    series: [
      {
        name: 'L',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'M',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'X',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'J',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'V',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'S',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      },
      {
        name: 'D',
        data: generateData(18, {
          min: 0,
          max: 15
        })
      }
    ]
  }

  return (
    <Chart
      options={data.options}
      series={data.series}
      type="heatmap"
      height="350"
    />
  )
}
