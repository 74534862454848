import React, { useState } from 'react'
import { useLocation, Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { loginAction } from '../../store/actions/user.actions'
import { getAuthWithCode } from '../../services/auth.service'

import './oauth.scss'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function OauthCallback(props) {
  let query = useQuery();
  let dispatch = useDispatch();
  let [auth, setAuth] = useState(false)
  let [authError, setAuthError] = useState(false)

  const code = query.get('code');

  getAuthWithCode(code)
  .then((auth) => {
    dispatch(loginAction(
      {
        userId: 'random',
        role: 'guest',
        token: auth.access_token
      }
    ))
    setAuth(true)
  })
  .catch((error) => {
    setAuthError(error)
  })

  return (
    <div className="page login-page">
      <div className="oauth">
        <h6>Authenticating...</h6>
          <Route
            render={() => {
              if (auth) {
                return (
                  <Redirect to="/home" />
                )
              } else {
                if (authError) {
                  return (
                    <Redirect to="/login" />
                  )
                }
              }
            }}
          />
      </div>
    </div>
  )
}
