import axios from 'axios';
import { dispatch } from 'react-redux';

export const getAuthWithCode = function (code) {
  return axios
  .get(`/token?code=${code}`)
}

export const oauth = function () {
  return axios.get('/auth')
  .then((response) => {
    return response.data.url
  })
}