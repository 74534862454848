import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import './capability-filters.scss'
import {
  CAPABILITY_SORT,
  CAPABILITY_STATUS
} from '../../constants/capabilities'
import { defaultFilters } from '../../store/reducers/capabilities.reducer'
import { setFiltersAction } from '../../store/actions'

export function CapabilityFilter(props) {
  const [filters, setFilters] = useState({ ...defaultFilters })
  const dispatch = useDispatch()


  function onFromDateChange(evt) {
    const newState = { ...filters, startDate: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  function onToDateChange(evt) {
    const newState = { ...filters, endDate: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  function onSupplierChange(evt) {
    const newState = { ...filters, supplier: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  function onSortChange(evt) {
    const newState = { ...filters, sort: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  function onProgramChange(evt) {
    const newState = { ...filters, program: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  function onStatusChange(evt) {
    const newState = { ...filters, status: evt.target.value }
    setFilters(newState)
    dispatch(setFiltersAction(newState))
  }

  return (
    <div className={`capability-filters ${props.open ? 'open' : ''}`}>
      <div className="filter-search">
        <label>
          <strong>Search</strong> <input onKeyUp={props.onTextSearch} type="text"></input>
        </label>
      </div>
      <div className="filter-items">
        <label>
          <strong>From date:</strong> <input type="date" onChange={onFromDateChange} placeholder="dd/mm/yyyy"></input>
        </label>
        <label>
          <strong>To date:</strong> <input type="date" onChange={onToDateChange} placeholder="dd/mm/yyyy"></input>
        </label>

        {/* <label>
          Status:{' '}
          <select onChange={onStatusChange}>
            <option value={CAPABILITY_STATUS.OPEN}>Open</option>
            <option value={CAPABILITY_STATUS.CLOSED}>Closed</option>
            <option value={CAPABILITY_STATUS.PENDING_FEEDBACK}>
              Pending feedback
            </option>
            <option value={CAPABILITY_STATUS.VALIDATED}>Validated</option>
            <option value={CAPABILITY_STATUS.REJECTED}>Rejected</option>
          </select>
        </label> */}

        <label>
          <strong>Sort:</strong>{' '}
          <select onChange={onSortChange}>
            <option value={CAPABILITY_SORT.DEADLINE}>Incomming Deadline</option>
            <option value={CAPABILITY_SORT.CRL}>CRL</option>
            <option value={CAPABILITY_SORT.STATUS}>Last status</option>
            <option value={CAPABILITY_SORT.UPDATED}>Last Updated</option>
            <option value={CAPABILITY_SORT.CREATED}>Last Created</option>
          </select>
        </label>

      </div>
    </div>
  )
}
