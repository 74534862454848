import { defaultCrl } from './mocks/capability-crl.mock'

const defaultEvidences = [
  {
    level: 1,
    type: 'doc',
    filename: 'level1-evidence-attachment.pdf',
    path: '/files/level1-evidence-attachment.pdf',
    date: new Date()
  },
  {
    level: 1,
    type: 'xlsx',
    filename: 'level1-evidence-attachment (2).pdf',
    path: '/files/level1-evidence-attachment (2).pdf',
    date: new Date()
  }
]

const capabilities = [
  {
    id: '276086',
    title: 'New Supplier Selection Process',
    cluster: 'New EE Procurement Process',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    actionsRequired: 'Validation',
    description:
      'We are doing a selection process on the pre-development cycle. We need to check with different suppliers and ensure that certain practices are met. Including: sharing common values, a general purpose of being. This is the first step in our successful new project.',
    level: 2,
    crl: defaultCrl,
    team: [
      {
        id: '1',
        name: 'Team Name 1'
      },
      {
        id: '2',
        name: 'Team Name 2'
      },
      {
        id: '3',
        name: 'Team Name 3'
      }
    ],
    steering: [
      {
        id: '2',
        name: 'Team Name 2'
      }
    ],
    subCapabilities: [
      {
        id: '12312310',
        title: 'New Supplier Selection Process - 2MATECH Evaluation',
        supplier: {
          id: '1',
          name: '2MATECH'
        },
        description:
          '2MATECH is a long term supplier and we want to evaluate their decissions on the last changes made for the A320. We are sure that we can collaborate together.',
        level: 2,
        team: {
          name: 'Jessica Paetz',
          id: '1'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 2,
            type: 'comment-alt',
            text: 'Yeah, let\'s get more insights',
            date: new Date(),
            userId: '1'
          },
          {
            level: 2,
            type: 'comment',
            text: 'We just arrived to level 2! Congrats',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: 'sa',
        title: 'New Supplier Selection Process - 3A COMPOSITES Evaluation',
        supplier: {
          id: '2',
          name: '3A COMPOSITES'
        },
        description:
          '3A COMPOSITES is a long term supplier and we want to evaluate their decissions on the last changes made for the A320. We are sure that we can collaborate together.',
        level: 3,
        team: {
          name: 'Jessica Paetz',
          id: '1'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: '123123f',
        title: 'New Supplier selection process with ASD DIVISON PLANT',
        supplier: {
          id: '5',
          name: 'ASD DIVISON PLANT'
        },
        description:
          'ASD DIVISON PLANT is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Moritz Sandel requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      }
    ]
  },
  {
    id: '1212313',
    title: 'Collaboration with suppliers through the exchange',
    cluster: 'New WoW with suppliers',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    actionsRequired: 'Validation',
    description:
      'We need to share our models with different suppliers to create a more agile environment. It is time to bring down some barriers in the communication.',
    level: 6,
    crl: defaultCrl,
    team: [
      {
        id: '1',
        name: 'Team Name 1'
      },
      {
        id: '2',
        name: 'Team Name 2'
      },
      {
        id: '3',
        name: 'Team Name 3'
      }
    ],
    steering: [
      {
        id: '2',
        name: 'Team Name 2'
      }
    ],
    subCapabilities: [
      {
        id: '9123',
        title: 'Collaboration with 2MATECH through wing model sharing',
        supplier: {
          id: '1',
          name: '2MATECH'
        },
        description:
          '2MATECH is a long term supplier and we want to see if they can provide us early stage developments of the wing parts and software models for the simulation',
        level: 6,
        team: {
          name: 'Jessica Paetz',
          id: '1'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 2,
            type: 'comment',
            text: 'We just arrived to level 2! Congrats',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: '3939494',
        title: 'Model sharing evaluation with 3A COMPOSITES',
        supplier: {
          id: '2',
          name: '3A COMPOSITES'
        },
        description:
          '3A COMPOSITES is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Jessica Paetz',
          id: '1'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      }
    ]
  },
  {
    id: 'dk204',
    title: 'Digital Collaboration with Shared tools and platforms',
    cluster: 'New WoW with suppliers',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    actionsRequired: 'Validation',
    description:
      'We have identified 10 platforms and tools from sales to development that, if shared, would increase our performance about a 30%.',
    level: 5,
    crl: defaultCrl,
    team: [
      {
        id: '1',
        name: 'Team Name 1'
      },
      {
        id: '2',
        name: 'Team Name 2'
      },
      {
        id: '3',
        name: 'Team Name 3'
      }
    ],
    steering: [
      {
        id: '2',
        name: 'Team Name 2'
      }
    ],
    subCapabilities: [
      {
        id: '9123',
        title: 'Identifying 3D ICOM available tools',
        supplier: {
          id: '3',
          name: '3D ICOM GMBH & CO KG'
        },
        description:
          '3D ICOM GMBH & CO KG, on 3D production systems, shares several tools with us',
        level: 2,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 2,
            type: 'comment',
            text: 'We just arrived to level 2! Congrats',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: '123123',
        title:
          'Model sharing evaluation with 3P PRODUCTOS PLASTICOS PERFORMANTES',
        supplier: {
          id: '25',
          name: '3P PRODUCTOS PLASTICOS PERFORMANTES'
        },
        description:
          '3P PRODUCTOS PLASTICOS PERFORMANTES is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Moritz Sandel requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: 'as193949',
        title: 'Model sharing evaluation with ASD DIVISON PLANT',
        supplier: {
          id: '5',
          name: 'ASD DIVISON PLANT'
        },
        description:
          'ASD DIVISON PLANT is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Moritz Sandel requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      }
    ]
  },
  {
    id: '49nsj392',
    title: 'Collaboration with complementary parties',
    cluster: 'New WoW with suppliers',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    actionsRequired: '',
    description:
      'Identified as a key factor for agility we want to collaborate with different partners (startups, PS, with PM, PJM). In order to do so we need to re-evaluate several things',
    level: 2,
    crl: defaultCrl,
    team: [
      {
        id: '1',
        name: 'Team Name 1'
      },
      {
        id: '2',
        name: 'Team Name 2'
      },
      {
        id: '3',
        name: 'Team Name 3'
      }
    ],
    steering: [
      {
        id: '2',
        name: 'Team Name 2'
      }
    ],
    subCapabilities: [
      {
        id: '12312310',
        title: 'Analysis collaboration with 3M COMPANY',
        supplier: {
          id: '7',
          name: '3M COMPANY'
        },
        description:
          '3M COMPANY is a long term supplier and we want to evaluate their decissions on the last changes made for the A320. We are sure that we can collaborate together.',
        level: 2,
        team: {
          name: 'Jonh Doe',
          id: '3'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 2,
            type: 'comment',
            text: 'We just arrived to level 2! Congrats',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: 's123a',
        title: 'Collaboration with 3M DEUTSCHLAND',
        supplier: {
          id: '8',
          name: '3M DEUTSCHLAND'
        },
        description:
          '3M DEUTSCHLAND is a long term supplier and we want to evaluate their decissions on the last changes made for the A320. We are sure that we can collaborate together.',
        level: 3,
        team: {
          name: 'Jonh Doe',
          id: '3'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: '123123f',
        title: 'Collaboration with external parties with ASD DIVISON PLANT',
        supplier: {
          id: '5',
          name: 'ASD DIVISON PLANT'
        },
        description:
          'ASD DIVISON PLANT is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdasd',
            type: 'comment',
            text: 'I will request the level up after this check.',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Moritz Sandel requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      }
    ]
  },
  {
    id: '49nsj392',
    title: 'Collaboration with complementary parties',
    cluster: 'New WoW with suppliers',
    group: 'group1',
    startDate: new Date(),
    endDate: new Date(),
    actionsRequired: '',
    description:
      'Identified as a key factor for agility we want to collaborate with different partners (startups, PS, with PM, PJM). In order to do so we need to re-evaluate several things',
    level: 2,
    crl: defaultCrl,
    team: [
      {
        id: '1',
        name: 'Team Name 1'
      },
      {
        id: '2',
        name: 'Team Name 2'
      },
      {
        id: '3',
        name: 'Team Name 3'
      }
    ],
    steering: [
      {
        id: '2',
        name: 'Team Name 2'
      }
    ],
    subCapabilities: [
      {
        id: '12312310',
        title: 'Rules for multiple sourcing',
        supplier: {
          id: '14',
          name: '3M FRANCE SA'
        },
        description:
          '3M FRANCE is a long term supplier and we want colaborate evaluating roules for multiple sourcing.',
        level: 8,
        team: {
          name: 'Jonh Doe',
          id: '3'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 2,
            type: 'comment',
            text: 'We just arrived to level 2! Congrats',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "routing-evidences-1.doc"',
            filename: 'routing-evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Silvia Paetz requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: 's123a',
        title: 'Collaboration during the in cychle phase',
        supplier: {
          id: '18',
          name: '3M INDUSTRIAL TAPE & SPECIALTIES'
        },
        description:
          '3M INDUSTRIAL TAPE & SPECIALTIES evaluate possible colaboration during the cychle phase.',
        level: 3,
        team: {
          name: 'Jonh Doe',
          id: '3'
        },
        actionsRequired: 'Validation',
        evidences: [
          {
            level: 1,
            type: 'doc',
            filename: 'level1-evidence-attachment.pdf',
            path: '/files/level1-evidence-attachment.pdf',
            date: new Date()
          },
          {
            level: 1,
            type: 'xlsx',
            filename: 'level1-evidence-attachment (2).pdf',
            path: '/files/level1-evidence-attachment (2).pdf',
            date: new Date()
          }
        ],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            id: 'asdaasdssd',
            type: 'comment',
            text: 'The evidence seems satisfying',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'file-upload',
            id: '123123asd',
            text: 'Uploaded the doc "evidence1.doc"',
            filename: 'evidence1.doc',
            filepath: '/files/evidence1.doc',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      },
      {
        id: '123123f',
        title: 'Co-design to value methodology',
        supplier: {
          id: '5',
          name: 'ASD DIVISON PLANT'
        },
        description:
          'ASD DIVISON PLANT is a long term supplier and we want to see if they can provide access to the new electric engine',
        level: 3,
        team: {
          name: 'Moritz Sandel',
          id: '2'
        },
        actionsRequired: 'Validation',
        evidences: [...defaultEvidences],
        activity: [
          {
            level: 3,
            type: 'level-up-request',
            text: 'Level 3 reached.',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 2,
            type: 'comment',
            text: 'After a long talk we decided to level up to 3',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user accepted the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The user requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text: 'I think the evidences are not enough to level up',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'The capability steering user declined the request',
            date: new Date(),
            userId: '2'
          },
          {
            level: 1,
            type: 'level-up-request',
            text: 'Moritz Sandel requested to level up this capability',
            date: new Date(),
            userId: '1'
          },
          {
            level: 1,
            type: 'comment',
            text:
              'I communicated with the supplier and they seem aligned on our needs and satisfactions, requesting level up',
            date: new Date(),
            userId: '2'
          }
        ]
      }
    ]
  }
]

// TODO: filters
// TODO: service integration
// TODO: send filters to back
export function getCapabilities(filters) {
  return Promise.resolve(capabilities)
}

export function getCapability(capabilityId) {
  return Promise.resolve(
    capabilities.find(capability => capability.id === capabilityId)
  )
}
