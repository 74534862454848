import { CAPABILITY_STATUS } from "../../constants/capabilities"

export const defaultCrlValidations = [
  {
    title: 'Maturity',
    validator: {
      id: 'userId1',
      avatar: 'https://i.pravatar.cc/150?u=user1.svg'
    }
  },
  {
    title: 'Design for value: RC reduction/NRC reduction',
    validator: {
      id: 'userId2',
      avatar: 'https://i.pravatar.cc/150?u=user2.svg'
    }
  },
  {
    title: 'Customization lead-time reduction',
    validator: {
      id: 'userId1',
      avatar: 'https://i.pravatar.cc/150?u=user1.svg'
    }
  },
  {
    title: 'Ramp-up',
    validator: {
      id: 'userId1',
      avatar: 'https://i.pravatar.cc/150?u=user1.svg'
    }
  },
  {
    title: 'Availability and Reliability',
    validator: {
      id: 'userId1',
      avatar: 'https://i.pravatar.cc/150?u=user1.svg'
    }
  },
  {
    title: 'Services',
    validator: {
      id: 'userId1',
      avatar: 'https://i.pravatar.cc/150?u=user1.svg'
    }
  },
  {
    title: 'Quality',
    validator: {
      id: 'userId2',
      avatar: 'https://i.pravatar.cc/150?u=user2.svg'
    }
  }
]

export const defaultCrl = {
  0: {
    title: 'Basic principles are observed',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.VALIDATED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  1: {
    title: 'Basic potential values is recognized',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.VALIDATED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  2: {
    title: 'Proven in other industrial applications and are documented for Airbus applications',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.VALIDATED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  3: {
    title: 'Experimented at Airbus on stand alone use cases with actual problems and data sets',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.OPEN,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  4: {
    title: 'Has been completely run on use cass and is proven to be applicable to intended fields',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.DISABLED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  5: {
    title: 'Has been applied on representative use cases for the complete intended fields and led results in line with expected benefits',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.DISABLED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  6: {
    title: 'Deployed on significant portions of the application fields',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.DISABLED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  7: {
    title: 'Fully deployed operationally',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.DISABLED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  },
  8: {
    title: 'Enriched and tuned from operational experience',
    description: 'Required following vaidations from Capability Team:',
    startDate: new Date(),
    endDate: new Date(),
    status: CAPABILITY_STATUS.DISABLED,
    updatedDate: new Date(),
    validatedBy: '1',
    validations: defaultCrlValidations
  }
}
